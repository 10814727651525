import { Player } from '@lottiefiles/react-lottie-player'
import ArrowFlow from '../../assets/images/lottie/arrow_flow.json'
import { ButtonTapHighlight } from '../../components'
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { DateTime } from 'luxon'
import { store } from '../../store'
import { selectSettingsMediaFloatingButton, setMediaFloatingButton } from '../../store/settings'
import { useSelector } from 'react-redux'
import { classNames } from '@tmap-web-lib/utils'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'

const MARGIN_HEIGHT = 12
const CONTENT_HEIGHT = 0.8

interface Props {
  topRef: MutableRefObject<number>
  mediaRef: MutableRefObject<HTMLDivElement | null>
  isBenefitPopularVisible: boolean
  eventLog: () => TmapLogBuilder
}

function MainFloatingButton(props: Props) {
  const {
    topRef,
    mediaRef,
    isBenefitPopularVisible,
    eventLog,
  } = props
  const ref = useRef(null)
  const floatingButtonClicked = useSelector(selectSettingsMediaFloatingButton)
  const [isVisible, setIsVisible] = useState(false)

  const handleScroll = useCallback(() => {
    const screenHeight = window.innerHeight
    const mediaOffsetTop = mediaRef.current?.offsetTop || 0
    const mediaHeight = (mediaRef.current?.offsetHeight || 0) * CONTENT_HEIGHT // 미디어 컴포넌트가 80% 화면이 노출되면 플로팅 버튼 미표출
    const offsetTop = mediaOffsetTop - screenHeight + mediaHeight
    const scrollY = window.scrollY

    if (offsetTop <= scrollY) {
      setIsVisible(false)
      store.dispatch(setMediaFloatingButton(DateTime.now().toISO()))
    }
  },[mediaRef])

  const handleClick = useCallback(() => {
    eventLog().set('action_id', 'tap.button_new_contents').send()
    window.scrollTo({ behavior: 'smooth', top: topRef.current - MARGIN_HEIGHT }) // 여백에 랜딩되도록 margin-top 값인 12px 뺌
  },[topRef, eventLog])

  useEffect(() => {
    if (floatingButtonClicked) {
      const now = DateTime.now().toISO()
      const midnight = DateTime.fromISO(floatingButtonClicked).plus({ days: 1 }).startOf('day').toISO()

      setIsVisible(now > midnight)
    } else {
      setIsVisible(true)
    }
  }, [floatingButtonClicked])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return (
    <CSSTransition
      classNames={'bottom-full-slide-up'}
      unmountOnExit={true}
      timeout={300}
      in={isVisible}
      nodeRef={ref}
      appear
    >
      <div className={classNames("main_floating", !isBenefitPopularVisible ? 'bottom-changed' : '')} ref={ref}>
        <ButtonTapHighlight
          className="main_floating_btn"
          isBeforePseudoElement
          onClick={handleClick}
        >
          콘텐츠 보기
          <Player
            className="main_floating_btn_ico"
            src={ArrowFlow}
            loop={true}
            autoplay
          />
        </ButtonTapHighlight>
      </div>
    </CSSTransition>
  )
}

export { MainFloatingButton }
