import React, { useCallback, useMemo, useState } from 'react'
import { Button, ButtonTapHighlight } from '../../components'
import { useOpenServiceUrl } from '../../hooks'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { SectionCard, Icon } from '../../components'
import { shuffle } from 'lodash-es'
import { ReportsResponseData } from '@tmap-web-lib/remote-api-client/frontman'
import { formatFloorKilometers } from '../../utils'
import { BenefitList } from '@tmap-web-lib/remote-api-client/frontman'
import { DateTime } from 'luxon'
import { classNames } from '@tmap-web-lib/utils'

import s from '../../styles/modules/main/MainBenefitBanner.module.scss'

type discountInsuranceItem = {
  benefitId: string
  benefitName: string
  insurerCode: string
  isDiscount: boolean
  link: string
  imgUrl: string
  discountScore: number
  discountTotalMileage: number
  discountRecentMileage: number
  discountCriteriaCondition?: string
}

type InsuranceDiscountCriteria = {
  discountCriteriaCount: number
  list: discountInsuranceItem[]
}

interface Props {
  benefitData?: BenefitList[]
  drivingScoreData?: ReportsResponseData
  insRegDate?: Nullable<string>
  eventLog: () => TmapLogBuilder
}

function MainBenefitBanner(props: Props) {
  const { benefitData, drivingScoreData, insRegDate, eventLog } = props
  const openServiceUrl = useOpenServiceUrl()

  const [isExpand, setExpand] = useState(false)

  const insurance = useMemo(() => {
    if (!benefitData || !drivingScoreData) return

    const result: InsuranceDiscountCriteria = {discountCriteriaCount: 0, list: []}
    const {score = 0, totalMileage = 0, recentMileage = 0} = drivingScoreData
    const list = shuffle(benefitData.filter((item) => !!item.option.insurance?.isSpecialContract))

    list.forEach((item) => {
      if (item.option.insurance && item.option.insurance.discountCriteria) {
        const {discountCriteria} = item.option.insurance
        const lastIndex = discountCriteria?.scoreAndRates.length - 1
        const discountScore = discountCriteria?.scoreAndRates[lastIndex].score
        const obj = {
          benefitId: item.benefitId,
          benefitName: item.benefitName,
          insurerCode: item.option.insurance.insurerCode || '',
          isDiscount: false,
          link: item.option.link?.landingUrl || '',
          imgUrl: item.benefitImageUrl,
          discountScore: discountScore,
          discountTotalMileage: discountCriteria?.totalMileage || 0,
          discountRecentMileage: discountCriteria?.recentMileage || 0,
          discountCriteriaCondition: '',
        }

        if (score >= discountScore && totalMileage >= discountCriteria?.totalMileage && recentMileage >= discountCriteria?.recentMileage) {
          obj.isDiscount = true
          result.discountCriteriaCount++
          result.list.unshift(obj)
        } else {
          const total = discountCriteria?.totalMileage - totalMileage
          const recent = discountCriteria?.recentMileage - recentMileage
          const condition: string[] = []

          if (total > 0 || recent > 0) {
            const mileage = Math.max(total, recent)
            condition.push(formatFloorKilometers(mileage))
          }
          if (score < discountScore) {
            condition.push(`${discountScore - score}점`)
          }
          obj.discountCriteriaCondition = condition.join(', ')
          result.list.push(obj)
        }
      }
    })

    const discountCriteriaCount = result.discountCriteriaCount
    if (discountCriteriaCount > 2 && discountCriteriaCount < list.length) {
      return {...result, list: [...result.list.slice(0, 2), ...result.list.slice(discountCriteriaCount, discountCriteriaCount + 1)]}
    }
    return {...result, list: [...result.list.slice(0, 3)]}
  }, [benefitData, drivingScoreData])

  const message = useMemo(() => {
    if (insRegDate) {
      if (insurance?.discountCriteriaCount) {
        if (DateTime.now().month === DateTime.fromISO(insRegDate).month) {
          return {
            title: '자동차보험 가입월',
            description: '운전점수 혜택 받고 가입하세요.'
          }
        } else if (DateTime.now().plus({ month: 1 }).month === DateTime.fromISO(insRegDate).month) {
          return {
            title: '자동차보험 만료 1달 전',
            description: '만기 30일 전부터 가입할 수 있어요.'
          }
        } else {
          return {
            title: '내 자동차보험 혜택',
            description: '내 운전점수와 주행거리로 모은 혜택'
          }
        }
      } else {
        return {
          title: '내 자동차보험 혜택',
          description: '점수, 주행거리에 따른 혜택을 모아보세요.'
        }
      }
    } else {
      if (insurance?.discountCriteriaCount) {
        return {
          title: '내 자동차보험 혜택',
          description: '내 운전점수와 주행거리로 모은 혜택'
        }
      } else {
        return {
          title: '내 자동차보험 혜택',
          description: '점수, 주행거리에 따른 혜택을 모아보세요.'
        }
      }
    }
  }, [insurance, insRegDate])

  const handleClick = useCallback((item: discountInsuranceItem) => {
    const extra = { seq: item.benefitId }

    eventLog()
      .set('action_id', item.isDiscount ? 'tap.check_discount_rate' : 'tap.check_unmet_conditions')
      .addCustomField('insurance_id', item.insurerCode)
      .send()
    openServiceUrl(`${item.link}&extra=${encodeURIComponent(JSON.stringify(extra))}`)
  }, [eventLog, openServiceUrl])

  const handleClickMore = useCallback(() => {
    eventLog().set('action_id', 'tap.view_more_my_benefits').send()
    openServiceUrl('tmap://life?pageid=benefit')
  }, [eventLog, openServiceUrl])

  const handleExpand = useCallback((isConditionsMet: boolean) => {
    eventLog()
      .set('action_id', 'tap.benefit')
      .addCustomField('msg_type', isConditionsMet ? 'benefit_conditions_met' : 'benefit_conditions_not_met')
      .send()
    setExpand(true)
  }, [eventLog])

  if (!insurance) return null

  return (
    <SectionCard>
      <div className={s.wrap}>
        <div className={s.header}>
          <div>
            <h2 className={s.header_title}>{message.title}</h2>
            <p className={s.header_desc}>{message.description}</p>
          </div>

          {!isExpand && (
            <>
              {insurance.discountCriteriaCount ? (
                <ButtonTapHighlight
                  className={classNames(s.header_expand, s.valid)}
                  onClick={() => handleExpand(true)}
                >
                  {insurance.discountCriteriaCount}개
                  <Icon iconName={'IconArrowDown'} size={'xs'} color={'--text-success-bolder'} />
                </ButtonTapHighlight>
              ) : (
                <ButtonTapHighlight
                  className={s.header_expand}
                  onClick={() => handleExpand(false)}
                >
                  <Icon iconName={'IconArrowDown'} size={'xs'} color={'--icon-secondary'} />
                </ButtonTapHighlight>
              )}
            </>
          )}
        </div>

        <div className={classNames(s.info, isExpand ? s.expand : undefined)}>
          <div className={s.status}>
            <img
              src={require('../../assets/images/graph_bar_2.svg').default}
              width="24"
              height="24"
              alt=""
            />
            <span>누적 <em>{formatFloorKilometers(drivingScoreData?.totalMileage || 0)}</em></span>
            <span>6개월 <em>{formatFloorKilometers(drivingScoreData?.recentMileage || 0)}</em></span>
          </div>
          <ul className={s.list}>
            {insurance.list.map((item, index) => {
              return (
                <li key={index} className={s.list_item}>
                  <div className={s.list_item_insure}>
                    <img
                      src={item.imgUrl}
                      width="36"
                      height="36"
                      alt=""
                    />
                    <span className={s.list_item_name}>{item.benefitName}</span>
                  </div>
                  {item.isDiscount ? (
                    <ButtonTapHighlight className={s.list_item_view} onClick={() => handleClick(item)}>
                      할인율 보기
                      <Icon iconName={"IconArrowRight"} size={'xs'} color={"--wb-white"} />
                    </ButtonTapHighlight>
                  ) : (
                    <ButtonTapHighlight className={s.list_item_condition} onClick={() => handleClick(item)}>
                      {item.discountCriteriaCondition} 필요
                      <Icon iconName={"IconArrowRight"} size={'xs'} color={"--icon-tertiary"} />
                    </ButtonTapHighlight>
                  )}
                </li>
              )
            })}
          </ul>
          <Button
            variant={'default'}
            styleHeight={52}
            borderRadius={'6px'}
            className={s.btn_more}
            onClick={handleClickMore}
          >
            더 받을 수 있는 혜택 보기
            <Icon iconName={"IconArrowRight"} color={"--icon-primary"} />
          </Button>
        </div>
      </div>
    </SectionCard>
  )
}

export { MainBenefitBanner }
